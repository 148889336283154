(function () {
  'use strict';

  /**
   * @ngdoc filter
   * @name wbAccount.wbCompany.filter:cardStatusClassName
   *
   * @description
   *
   * @param {Array} input The array to filter
   * @returns {Array} The filtered array
   *
   */
  angular
    .module('wbAccount.wbCompany')
    .filter('cardStatusClassName', cardStatusClassName);

  function cardStatusClassName() {
    return function (card) {
      var classNames = [];

      switch (card.status) {
        case 'ACT':
          classNames.push('status-ACT zmdi-check-circle');
          break;
        case 'SLD':
          classNames.push('status-SLD zmdi-block');
          break;
        case 'LSC':
        case 'STL':
        case 'DMG':
          classNames.push('status-LSC zmdi-close');
          break;
        case 'BLK':
          classNames.push('status-BLK zmdi-minus-circle');
          break;
        default:
          classNames.push('status-? zmdi-help-circle');
          break;
      }

      return classNames.join(' ');
    };
  }
}());
